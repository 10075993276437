import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import { deleteExpectedSales } from "features/invoices/sales/api/sales";
import useFormatter from "hooks/useFormatter";
import { AiFillEye } from "react-icons/ai";
import { MdOutlinePayment } from "react-icons/md";
import { toast } from "react-toastify";

export const statusColor = [
  {
    color: "#00FF00",
    name: "paid",
  },
  {
    color: "#FF0000",
    name: "unpaid",
  },
  {
    color: "#d0d000",
    name: "partial",
  },
];

export function getStatusColor(name) {
  return statusColor.find((item) => item?.name === name.toLowerCase())?.color;
}

const InstallmentTable = ({
  loading,
  allPayment,
  setReloadMain,
  setAddPayment,
  setCurrPayment,
  setAllPayment,
  symbol,
  setMakePayment,
  setViewPayment,
}) => {
  const { formatCurrencyAmount } = useFormatter();
  const rows = allPayment;
  const columns = [
    { id: "month", label: "Month" },
    { id: "year", label: "Year" },
    { id: "expected_payment_type", label: "Installment" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
    { id: "total_due", label: "Due" },
    { id: "total_paid", label: "Total Paid" },
    {
      id: "action",
      label: "Actions",
      style: { width: "130px" },
      align: "center",
    },
  ];

  const handleDelete = async (id) => {
    try {
      const result = await deleteExpectedSales(id);
      if (result.success) {
        toast.success(result.message);
        setAllPayment(allPayment.filter((task) => task.id !== id));
        setReloadMain((prev) => !prev);
      } else {
        toast.error(result.message);
      }
    } catch {}
  };

  const handleEditBtn = (value) => {
    setCurrPayment(value);
    setAddPayment(true);
  };
  const handlePaymentBtn = (value) => {
    setCurrPayment(value);
    setMakePayment(true);
  };
  return (
    <>
      {!loading ? (
        <>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 256px)",
              overflowX: "auto",
              ml: "-20px",
              mr: "-20px",
              width: "calc(100% + 40px)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {column.id === "status" ? (
                              <Box
                                sx={{
                                  bgcolor: `${getStatusColor(row?.status)}15`,
                                  color: getStatusColor(row?.status),
                                  textAlign: "center",
                                  padding: "3px 5px",
                                  borderRadius: "20px",
                                  width: "100px",
                                  mt: "5px",
                                  fontSize: "13px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {value}
                              </Box>
                            ) : column.id === "total_paid" ||
                              column.id === "amount" ||
                              column.id === "total_due" ? (
                              symbol + formatCurrencyAmount(value)
                            ) : column.id === "expected_payment_type" ? (
                              <Typography
                                textTransform={"capitalize"}
                                variant="body2"
                              >
                                {value === "charge"
                                  ? "Subscription Fee"
                                  : value?.split("_").join(" ")}
                              </Typography>
                            ) : column.id === "action" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip title="View Details">
                                  <IconButton
                                    onClick={() => {
                                      setCurrPayment(row);
                                      setViewPayment(true);
                                    }}
                                  >
                                    <AiFillEye />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Make Payment">
                                  <IconButton
                                    onClick={() => handlePaymentBtn(row)}
                                  >
                                    <MdOutlinePayment />
                                  </IconButton>
                                </Tooltip>
                                <IconButton onClick={() => handleEditBtn(row)}>
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDelete(row?.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            ) : (
                              value || "-"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Total amount count */}
          <Box
            sx={{
              mt: "10px",

              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "500" }} align="right">
              Total Amount:
            </Typography>
            <Typography sx={{ fontWeight: "500" }} align="right">
              {symbol}
              {formatCurrencyAmount(rows.reduce((acc, curr) => acc + curr.amount, 0))}
            </Typography>
          </Box>
        </>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
    </>
  );
};

export default InstallmentTable;
