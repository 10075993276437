import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import { deleteMakePayment } from "features/invoices/sales/api/sales";
import useFormatter from "hooks/useFormatter";
import { toast } from "react-toastify";

const MakePaymentTable = ({
  loading,
  allPayment,
  setReloadMain,
  setAddPayment,
  setCurrPayment,
  setAllPayment,
  symbol,
}) => {
  const { formatDate, formatCurrencyAmount } = useFormatter();
  const rows = allPayment;
  const columns = [
    { id: "month", label: "Month" },
    { id: "year", label: "Year" },
    {
      id: "payment_method_name",
      label: "Payment Method",
    },
    { id: "expected_payment_type", label: "Installment" },

    {
      id: "total_paid",
      label: "Total Paid",
    },
    { id: "payment_date", label: "Payment Date" },
    {
      id: "action",
      label: "Actions",
      style: { width: "130px" },
      align: "center",
    },
  ];

  const handleDelete = async (id) => {
    try {
      const result = await deleteMakePayment(id);
      if (result.success) {
        toast.success(result.message);
        setAllPayment(allPayment.filter((task) => task.id !== id));
        setReloadMain((prev) => !prev);
      } else {
        toast.error(result.message);
      }
    } catch {}
  };

  const handleEditBtn = (value) => {
    setCurrPayment(value);
    setAddPayment(true);
  };

  return (
    <>
      {!loading ? (
        <>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 256px)",
              overflowX: "auto",
              ml: "-20px",
              mr: "-20px",
              width: "calc(100% + 40px)",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "total_paid" ? (
                              <>{symbol + formatCurrencyAmount(value)}</>
                            ) : column.id === "payment_date" ? (
                              formatDate(value)
                            ) : column.id === "expected_payment_type" ? (
                              <Typography
                                textTransform={"capitalize"}
                                variant="body2"
                              >
                                {value === "charge"
                                  ? "Subscription Fee"
                                  : value?.split("_").join(" ")}
                              </Typography>
                            ) : column.id === "action" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleEditBtn(row)}>
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <Delete />
                                </IconButton>
                              </Box>
                            ) : (
                              value || "-"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Total amount count */}
          <Box
            sx={{
              mt: "10px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "500" }} align="right">
              Total Paid:
            </Typography>
            <Typography sx={{ fontWeight: "500" }} align="right">
              {symbol}
              {formatCurrencyAmount(
                rows.reduce((acc, curr) => acc + curr.total_paid, 0)
              )}
            </Typography>
          </Box>
        </>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
    </>
  );
};

export default MakePaymentTable;
