import { ImBin } from "react-icons/im";
// MUI COMPONENTS
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";




const Alert = ({ open, handleClose, type, icon, title, desc, buttons }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      sx={{
        "& div": {
          borderRadius: "8px",
        },
      }}
    >
      <Box sx={{ width: "400px" }}>
        <Box
          sx={{
            paddingTop: "30px",
            fontSize: "30px",
            fontWeight: "500",
            color: "#ff6868",
            textAlign: "center",
          }}
        >
          {icon || <ImBin />}
        </Box>
        <Box
          sx={{
            marginTop: "-5px",
            paddingBottom: "25px",
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          {title}
        </Box>
        <Box sx={{ backgroundColor: "#f8f8f8", paddingBottom: "10px" }}>
          <Box sx={{ padding: "10px 25px" }}>{desc}</Box>
          {buttons && (
            <Stack
              direction="row"
              gap="10px"
              justifyContent="center"
              padding="15px"
            >
              {buttons}
            </Stack>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default Alert;
